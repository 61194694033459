import React, { lazy, useState } from "react";
import Layout from "./components/layout";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes } from "react-router-dom";
const Login = lazy(() => import("./pages/login/login"));

// if (window.location.search.split("=")[0] === "?token") {
//   sessionStorage.setItem("a_tk", window.location.search.split("=")[1]);
// } else if (!sessionStorage.getItem("a_tk")) {
//   window.location.replace("http://127.0.0.1");
// }

function App() {
  // const url = "http://localhost:4009/api/v1/";
  const url = "https://api-caerry-v.onrender.com/api/v1/";
  // const homeUrl = "http://127.0.0.1:5500/";
  const homeUrl = "https://caerry.netlify.app/";
  const [token, setToken] = useState(sessionStorage.getItem("a_tk") || "");
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("user")) || {}
  );
  const [notificationId, setNotificationId] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const setTokenHandler = (_token) => {
    setToken(_token);
    sessionStorage.setItem("a_tk", _token);
  };

  const setUserHandler = (_user) => {
    setUser(_user);
    sessionStorage.setItem("user", JSON.stringify(_user));
  };

  const logoutHandler = () => {
    sessionStorage.clear();
    setToken("");
    setUser({});
    window.location.reload();
  };

  const setNotificationIdHandler = (not) => {
    setNotificationId(not);
  };

  return (
    <MainContext.Provider
      value={{
        url,
        homeUrl,
        token,
        user,
        setToken: setTokenHandler,
        setUser: setUserHandler,
        logout: logoutHandler,
        isNotificationOpen,
        toogleIsNotificationOpen: () =>
          setIsNotificationOpen(!isNotificationOpen),
        notificationId,
        setNotificationId: setNotificationIdHandler,
      }}
    >
      {/* <Routes>
        {token && (
          <Route
            path="/login"
            exact
            element={<Navigate replace to={"/dashboard"} />}
          />
        )}
        {token && (
          <Route
            path="/"
            exact
            element={<Navigate replace to={"/dashboard"} />}
          />
        )}
        {!token && (
          <Route path="/*" exact element={<Navigate replace to={"/login"} />} />
        )}

        <Route path="/login" exact element={<Login />} />
      </Routes> */}
      {/* {token && <Layout />} */}
      <Layout />
      <Toaster
        position="bottom-left"
        toastOptions={{
          style: {
            background: "#fc0",
            borderLeft: "5px solid #000",
            fontSize: "14px",
            color: "#000",
          },
        }}
      />
    </MainContext.Provider>
  );
}

export default App;

export const MainContext = React.createContext({
  url: null,
  homeUrl: null,
  sideDrawer: null,
  setSideDrawer: () => {},
  token: null,
  setToken: () => {},
  logout: () => {},
  setUser: () => {},
  user: null,
  notificationId: null,
  setNotificationId: null,
  isNotificationOpen: null,
  toogleIsNotificationOpen: () => {},
});
