import React, { lazy, Suspense, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MainContext } from "../App";
import Notification from "./notification";
import Loading from "./suspense/suspense";

const EventEdit = lazy(() => import("../pages/edit/eventEdit"));
const EventSendingTo = lazy(() => import("../pages/sendingTo/eventSendingTo"));
const EventFirstSend = lazy(() => import("../pages/first_send/eventFirstSend"));
const Eventbilling = lazy(() => import("../pages/billing/eventbilling"));
const EventRecieving = lazy(() => import("../pages/recieving/eventRecieving"));
const EventSending = lazy(() => import("../pages/sending/eventSending"));
const Home = lazy(() => import("../pages/home/eventHome"));
const Settings = lazy(() => import("../pages/settings/eventSettings"));
const GetQuote = lazy(() => import("../pages/getQuote/eventGetQuote"));
const Locate = lazy(() => import("../pages/location/location"));
const Login = lazy(() => import("../pages/login/login"));
const Orders = lazy(() => import("../pages/orders/eventOrders"));

const Body = () => {
  const CTX = useContext(MainContext);

  return (
    <div className="main">
      <Routes>
        {CTX.token && (
          <Route
            path="/login"
            exact
            element={<Navigate replace to={"/dashboard"} />}
          />
        )}
        {CTX.token && (
          <Route
            path="/"
            exact
            element={<Navigate replace to={"/dashboard"} />}
          />
        )}
        {!CTX.token && (
          <Route path="/*" exact element={<Navigate replace to={"/login"} />} />
        )}

        <Route path="/login" exact element={<Login />} />

        {CTX.token && (
          <Route
            path="/"
            exact
            element={<Navigate replace to={"/dashboard"} />}
          />
        )}
        {CTX.token && (
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            }
          />
        )}
        {CTX.token && (
          <Route
            path="/account/:userId/new"
            element={
              <Suspense fallback={<Loading />}>
                <EventRecieving />
              </Suspense>
            }
          />
        )}

        {CTX.token && (
          <Route
            path="/edit/:id"
            element={
              <Suspense fallback={<Loading />}>
                <EventEdit />
              </Suspense>
            }
          />
        )}

        {CTX.token && (
          <Route
            path="/:trackingNumber/locate/item/:code"
            element={
              <Suspense fallback={<Loading />}>
                <Locate />
              </Suspense>
            }
          />
        )}

        {CTX.token && (
          <Route
            path="/:id/orders"
            element={
              <Suspense fallback={<Loading />}>
                <Orders />
              </Suspense>
            }
          />
        )}

        {/* 
        <Route
          path="/send_to"
          element={
            <Suspense fallback={<Loading />}>
              <EventSendingTo />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/settings"
          element={
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path="/sent_orders"
          element={
            <Suspense fallback={<Loading />}>
              <EventSending />
            </Suspense>
          }
        />
        <Route
          path="/account/:userID/billing"
          element={
            <Suspense fallback={<Loading />}>
              <Eventbilling />
            </Suspense>
          }
        />
        <Route
          path="/account/send/:id"
          element={
            <Suspense fallback={<Loading />}>
              <EventFirstSend />
            </Suspense>
          }
        />
        <Route
          path="/send_to"
          element={
            <Suspense fallback={<Loading />}>
              <EventSendingTo />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/:userID/get/quote"
          element={
            <Suspense fallback={<Loading />}>
              <GetQuote />
              </Suspense>
            }
          /> */}
        {/* <Route
          path="/:userID/locate/item"
          element={
            <Suspense fallback={<Loading />}>
              <Locate />
            </Suspense>
          }
        /> */}
      </Routes>
      <Notification />
    </div>
  );
};

export default Body;
