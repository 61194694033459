import React from "react";
import Body from "./body";
import Nav from "./nav";
import Sidebar from "./sidebar";

const Layout = () => {
  return (
    <div>
      <Nav />
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <Body />
        </div>
      </div>
    </div>
  );
};

export default Layout;
