import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../App";
import { useParams } from "react-router-dom";
import {
  AiFillDelete,
  AiOutlineArrowLeft,
  AiOutlineDelete,
} from "react-icons/ai";

const Notification = () => {
  const match = useParams();
  const [text, setText] = useState("");
  const CTX = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [toggleAllNot, setToggleAllNot] = useState(false);
  const [listNots, setListNots] = useState(Array(0).fill("s"));

  const getConsignmentNotification = async () => {
    // console.log("notifiicationID HERE!! ===>> ", CTX.notificationId);

    if (loading) return;
    setLoading(true);
    try {
      // console.log("inputs HERE! ===> ", inputs);
      const postinng = await fetch(
        `${CTX.url}notification/get/${CTX.notificationId?.data}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const jsoned = await postinng.json();
      if (jsoned.e) {
        alert("Please try again, an error occurred");
        return;
      }

      setListNots(jsoned.d.reverse());
      setErr(false);
      setLoading(false);
    } catch (error) {
      alert("Please try again, an error occurred");
      setLoading(false);
      setErr(true);
      console.log(error);
    }
  };

  useEffect(() => {

    if(CTX.notificationId?.data?.length > 2) {
      getConsignmentNotification();
    }
  }, [CTX.notificationId, toggleAllNot]);

  const sendNotificationHandler = async () => {
    if (loading) return;
    if (text.trim().length < 2) return;
    setLoading(true);
    try {
      const postinng = await fetch(
        `${CTX.url}notification/post/${CTX.notificationId?.data}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            note: text,
          }),
        }
      );
      const jsoned = await postinng.json();
      if (jsoned.e) {
        alert("Please try again, an error occurred");
        return;
      }
      setText("");
      alert("Posting notification successful");
      setErr(false);

      setLoading(false);
    } catch (error) {
      alert("Please try again, an error occurred");
      setLoading(false);
      setErr(true);
      console.log(error);
    }
  };

  const deleteNotificationHandler = async (id, e) => {
    if (loading) return;
    setLoading(true);
    try {
      const postinng = await fetch(`${CTX.url}notification/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          note: text,
        }),
      });
      const jsoned = await postinng.json();
      if (jsoned.e) {
        alert("Please try again, an error occurred");
        return;
      }
      getConsignmentNotification();
      setErr(false);
      setLoading(false);
    } catch (error) {
      alert("Please try again, an error occurred");
      setLoading(false);
      setErr(true);
      console.log(error);
    }
  };

  // mapping list of notifications
  const mappedNotifications = listNots.map((v, i) => (
    <div
      style={{
        position: "relative",
        padding: ".5em",
        marginBottom: ".4em",
        border: "0.0625rem solid #b4bfc9",
      }}
      key={i}
    >
      <div>{v.note}</div>

      <AiFillDelete
        size={19}
        onClick={deleteNotificationHandler.bind(this, v._id)}
        color="#d00"
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "7px",
          right: "7px",
        }}
      />
    </div>
  ));

  return (
    <div id="support">
      <div
        id="support-modal"
        className={
          CTX.isNotificationOpen ? "modal fadeInUp in" : "modal fadeInUp"
        }
        tabIndex="-1"
        role="dialog"
        data-preloaded-event-type=""
        style={{ display: CTX.isNotificationOpen ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div id="modal-header-wrapper">
              <nav className="modal-header">
                <div id="support-modal-nav-tabs" role="tablist">
                  <a
                    className="modal-title active"
                    id="support-nav-notifications-tab"
                    data-toggle="tab"
                    href="#support-nav-notifications"
                    role="tab"
                    aria-controls="support-nav-notifications"
                    aria-selected="false"
                  >
                    Notifications
                    <span className="unread-badge"></span>
                  </a>
                  <span
                    className="close"
                    data-dismiss="modal"
                    href="#"
                    onClick={() => CTX.toogleIsNotificationOpen()}
                  >
                    +
                  </span>
                </div>
              </nav>
            </div>
            <div id="scroll-target"></div>
            <div className="tab-content">
              <div
                className="tab-pane fade active in"
                id="support-nav-notifications"
                role="tabpanel"
                aria-labelledby="support-nav-notifications-tab"
              >
                <div
                  // data-react-className="Dashboard.NotificationFeed"
                  data-react-props='{"token":"eyJhbGciOiJIUzUxMiJ9.eyJleHRlcm5hbF9hY2NvdW50X2lkIjoiYWYxMjEyNTItMGI0My00NDZmLWI1ZTctMzM5NzM0NWRjOTg1IiwiZXhwIjoxNjc4ODc0NDA3LCJpYXQiOjE2Nzg4NzMyMDd9.0H_4z3OFs8d57llOvP4eoX2LNAeqwEw84-UTaKvCj2B5x5xo5dwK-NhIR9Qi5odyEGEGVjDfHYXjQLebIv8yig","url":"https://sendle-hermes.herokuapp.com","tokenRefreshInterval":20}'
                  data-react-cache-id="Dashboard.NotificationFeed-0"
                >
                  <div>
                    <div className="notification-feed__controls">
                      {toggleAllNot ? (
                        <button
                          onClick={() => setToggleAllNot(!toggleAllNot)}
                          style={{ textDecoration: "none" }}
                        >
                          <AiOutlineArrowLeft
                            style={{
                              marginRight: ".1em",
                            }}
                          />
                          Back
                        </button>
                      ) : (
                        <button style={{ textDecoration: "none" }}>
                          Send notification to only these consignment
                        </button>
                      )}
                    </div>

                    {toggleAllNot && (
                      <div style={{ padding: "1em", boxSizing: "border-box" }}>
                        {loading ? (
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              marginTop: "50px",
                            }}
                          >
                            Loading...
                          </div>
                        ) : mappedNotifications.length < 1 ? (
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              marginTop: "50px",
                            }}
                          >
                            Empty notification{" "}
                          </div>
                        ) : (
                          mappedNotifications
                        )}
                      </div>
                    )}

                    {!toggleAllNot && (
                      <div
                        className="notification-feed--empty text-center sds-u-p_around--large sds-u-m_right--auto sds-u-m_left--auto"
                        style={{ marginTop: "2em" }}
                      >
                        <textarea
                          style={{ padding: "1em", width: "100%" }}
                          rows={13}
                          onChange={(e) => setText(e.target.value)}
                          value={text}
                        />

                        <p
                          style={{ textAlign: "left" }}
                          className="sds-u-fs--small sds-u-m_top--large"
                        >
                          Click
                          <span
                            onClick={() => setToggleAllNot(!toggleAllNot)}
                            style={{
                              color: "#008dcc",
                              textDecoration: "underline",
                              cursor: "pointer",
                              margin: "0px 3px",
                            }}
                          >
                            here
                          </span>
                          to view all notifications for this particular
                          consignment.
                        </p>

                        <div style={{ display: "flex", marginTop: "2em" }}>
                          <div
                            // to={`/account/${
                            //   Math.random().toString().split(".")[1]
                            // }/received_orders`}
                            onClick={() => sendNotificationHandler()}
                            className="sds-button  sds-button--primary  "
                          >
                            <span className="sds-button__text">
                              {loading ? "..." : "Send"}
                            </span>
                          </div>

                          {/* <button className="btn-get-quote sds-u-m_left--base sds-button sds-button--primary sds-button--ghost">
                            <span className="sds-button__text">
                              Add Location
                            </span>
                        </button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
